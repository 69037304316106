<template>
	<div v-if="tempShowHide">
		<dependency-filter-structure v-for="(item, index) in structuredFilters" :key="item.field_type_id" :parent-field="item" :selectedFilters="selectedFilters" @on-select="filterChanged"></dependency-filter-structure>

		<wbselectfield v-if="sublocalityFilter!==null" :key="sublocalityFilter.field_type_id" class="mb15" :name="sublocalityFilter.label" :field-type-id="sublocalityFilter.field_type_id" :values="sublocalityFilter.options" :selectedFilters="selectedFilters" :isMultiple="true" @on-select="filterChanged"></wbselectfield>

		<button class="btn btn-link text-primary mb20" @click="resetAllFilters">Reset filter</button>
	</div>
</template>
<script>
	import { getSublocalities } from '@/api/system';
	import { addZero, getReadableTime, getFilterDate, compareAndSwitchDates, getWeekday, getMonth } from '@/utils/appointment-helpers';
	import { showLoader, hideLoader, getFilterFields, createSelectOptions, getAllowedValuesFromFieldType, argsFilterMapToJSMap, isset, changeRouteQuery } from '@/utils/helpers';
	import { screenSizeMixin } from '@/mixins/screenSizeMixin';
	import VueScrollTo from 'vue-scrollto';

	export default {
		name: "AngeboteFilter",
		mixins: [screenSizeMixin],
		components: {
			WBSelectField: () => import('@/components/controls/WBSelectField.vue'),
			DependencyFilterStructure: () => import('@/components/controls/DependencyFilterStructure.vue'),
			ToggleSwitch: () =>import('@/components/controls/ToggleSwitch')
		},
		data() {
			return {
				tempShowHide: true,
				selectedCategory: null,
				expandFilter: false,
			}
		},
		props: {
			contentType: {
				type: Object,
				required: true,
			},
			selectedFilters: {
				type: Map,
				required: true,
			},
			structuredFilters: {
				type: Array,
				required: true
			},
			sublocalityFilter: {
				type: Object,
				required: true
			}
		},
		created(){
			this.$scrollTo();
		},
		methods: {
			showLoader, 
			hideLoader,
			getSublocalities, 
			createSelectOptions,
			getAllowedValuesFromFieldType,
			filterChanged(fieldTypeId,values){
				this.tempShowHide = false;
				this.expandFilter = false;
				this.selectedFilters.set(fieldTypeId, values);
				this.expandFilter = true;
				this.$emit('filterChanged', this.selectedFilters);
				this.$nextTick(() => {
					this.tempShowHide = true;
				});
			},
			filteringFinished(map){
				/*get's invoked when the user closes the more-filters-modal with the show results-btn this also means, that we'll have to start searching now*/
				this.expandFilter = false;
				this.selectedFilters = map;
				this.$emit('filterChanged', this.selectedFilters);
				this.expandFilter = true;
			},
			resetAllFilters(){
				this.tempShowHide = false;
				this.$emit('resetAllFilters', this.selectedFilters);
				this.$nextTick(() => {
					this.tempShowHide = true;
				});
			},
		}
	};
</script>
<style lang="scss" scoped="">
	@import '@/scss/_variables.scss';

	.filter-header {
		display: flex;
		margin-top: 30px;
		margin-bottom: 15px;

		.reset-btn {
			margin-left: auto;
		}
	}

	.time-picker-header {
		margin-top: 30px;
		margin-bottom: 15px;
	}
</style>
